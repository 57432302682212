

















































import { Component, Vue } from 'vue-property-decorator';
import KuspitAccountLinkingInitViewModel
  from '@/vue-app/view-models/components/contract-savings/kuspit-account-linking/kuspit-account-linking-init-view-model';

@Component({ name: 'KuspitAccountLinkingInit' })
export default class KuspitAccountLinkingInit extends Vue {
  kuspit_init_view_model = Vue.observable(
    new KuspitAccountLinkingInitViewModel(this),
  );
}
